@font-face {
	font-family: 'Mukta';
	src: url('./Mukta-ExtraLight.woff') format('woff'), url('./Mukta-ExtraLight.woff2') format('woff2');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Mukta';
	src: url('./Mukta-Light.woff') format('woff'), url('./Mukta-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Mukta';
	src: url('./Mukta-Regular.woff') format('woff'), url('./Mukta-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Mukta';
	src: url('./Mukta-Medium.woff') format('woff'), url('./Mukta-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Mukta';
	src: url('./Mukta-SemiBold.woff') format('woff'), url('./Mukta-SemiBold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Mukta';
	src: url('./Mukta-Bold.woff') format('woff'), url('./Mukta-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Mukta';
	src: url('./Mukta-ExtraBold.woff') format('woff'), url('./Mukta-ExtraBold.woff2') format('woff2');
	font-weight: 800;
	font-style: normal;
}
